$opacity-disable: 0.3;

.mat-button-wrapper > * {
    vertical-align: unset !important;
    align-self: center;
}

.mat-mini-fab {
    padding: 0 !important;
}

.mat-button-base {
    border-radius: 4px;
    margin: 4px;
    height: 36px;
    padding-left: 15px;
    padding-right: 15px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    max-width: var(--max-width);

    &.small {
        width: 142px;
        height: 36px;
    }

    &.medium {
        width: 180px;
        height: 40px;
    }

    &.large {
        width: 277px;
        height: 42px;
    }

    &.mat-button-disabled {
        opacity: $opacity-disable;
    }

    .mat-button-wrapper {
        display: inline-flex;
        width: 100%;
        justify-content: center;
        gap: 10px;
    }

    &.justify-start {
        justify-content: flex-start;

        .mat-button-wrapper {
            justify-content: flex-start;
        }
    }

    &:hover {
        opacity: 0.9;
        cursor: pointer;

        &.mat-button-disabled {
            opacity: $opacity-disable;
            cursor: auto;
        }
    }
}

.mat-button-base {
    &.icon-small {
        mat-icon {
            svg {
                width: 14px;
            }
        }
    }

    &.icon-large {
        mat-icon {
            svg {
                width: 24px;
            }
        }
    }
}

.mat-stroked-button.mat-button-disabled {
    border-color: rgba(0, 0, 0, 0.12);
}

.mat-icon-button {
    .mat-button-wrapper {
        height: 100%;
    }
}
