$hover-color: #f4fbff;
$transition-duration: 0.2s;

.dragover {
    &.child {
        outline: 2px solid var(--main-color);
    }
}

.left-node-container {
    .dragover {
        &.sibling-above .sibling-drag-line-above,
        &.sibling-below .sibling-drag-line-below {
            background-color: var(--main-color);

            .ellipse-decoration {
                z-index: 1;
                background-color: var(--main-color);
            }
        }
    }
}

.right-node-container {
    .dragover {
        &.sibling-above .sibling-drag-line-above,
        &.sibling-below .sibling-drag-line-below {
            background-color: var(--main-color);

            .ellipse-decoration {
                z-index: 1;
                background-color: var(--main-color);
            }
        }
    }
}

// invalid operation design
.child {
    &.childDisabled {
        outline-color: var(--error) !important;
    }
}

.sibling {
    &.siblingDisabled {
        [class^='sibling-drag-line'] {
            border-color: var(--error) !important;

            .ellipse-decoration {
                z-index: 1;
                background-color: var(--text-color-muted) !important;
            }
        }
    }
}

// MAKE LINK
// styles for invalid/valid interactions
// while dragging a node from the opposite tree
.node-container {
    .link-icon {
        display: none;
    }
}

.draggingSelf {
    opacity: 0.5;
    transition: opacity ease-in $transition-duration;
}

.draggingNodeFromOtherTree {
    &.linkDisabled {
        opacity: 0.5;
        transition: opacity ease-in $transition-duration;
    }

    &:not(.linkDisabled) {
        .dropzone {
            outline: var(--main-color) dashed 2px;
        }

        // hide show children button and show link-icon
        .toggle-children-button {
            display: none;
        }

        .link-icon {
            display: block;
        }
    }

    // while hovering a dragged node
    // (drag drop is disabled if the operation is invalid)
    // so dragover is only activated if operation is valid
    &.dragover {
        .dropzone {
            background-color: $hover-color;
        }
    }
}

body.inheritCursors * {
    cursor: inherit !important;
}
