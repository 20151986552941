@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    src: local('Segoe UI Regular'),
        url('../../assets/fonts/segoe-ui/Segoe UI.woff') format('woff');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: 400;
    src: local('Segoe UI Italic'),
        url('../../assets/fonts/segoe-ui/Segoe UI Italic.woff') format('woff');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    src: local('Segoe UI Semi-Bold'),
        url('../../assets/fonts/segoe-ui/Segoe UI Semi-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 800;
    src: local('Segoe UI Bold'),
        url('../../assets/fonts/segoe-ui/Segoe UI Bold.woff') format('woff');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: 800;
    src: local('Segoe UI Bold Italic'),
        url('../../assets/fonts/segoe-ui/Segoe UI Bold Italic.woff') format('woff');
}
