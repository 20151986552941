.mat-menu-panel.link-menu-container {
    max-width: 300px;
    width: 300px;
    background-color: #ffffff;
    margin-left: -35px;
    margin-top: -35px;

    .mat-menu-submenu-icon {
        visibility: hidden;
    }
    .mat-menu-item-submenu-trigger {
        padding-right: 10px !important;
    }
}

.mat-menu-panel.push-menu-container {
    max-width: 300px;
    width: 300px;
    background-color: #ffffff;
    margin-left: 25px;
}

.mat-menu-content.link-menu-container {
    padding: 0px !important;
}
