$font-weight: 500;

@mixin body {
    font-family: Segoe UI, sans-serif;
    font-style: normal;
    font-weight: $font-weight;
}

@mixin h1 {
    font-family: Segoe UI, sans-serif;

    font-size: 22px;
    font-style: normal;
    font-weight: calc(#{$font-weight} + 100);
    line-height: 27px;
    letter-spacing: 0;
}

@mixin h2 {
    font-family: Segoe UI, sans-serif;

    font-size: 20px;
    font-style: normal;
    font-weight: calc(#{$font-weight} - 100);
    line-height: 27px;
    letter-spacing: 0;
}

@mixin h3 {
    font-family: Segoe UI, sans-serif;

    font-size: 16px;
    font-style: normal;
    font-weight: calc(#{$font-weight} + 100);
    line-height: 21px;
    letter-spacing: 0;
}

@mixin h5 {
    font-family: Segoe UI, sans-serif;

    font-size: 14px;
    font-style: normal;
    font-weight: calc(#{$font-weight} + 100);
    line-height: 21px;
    letter-spacing: 0;
}

@mixin h1-semibold-20 {
    font-family: Segoe UI, sans-serif;

    font-size: 20px;
    font-style: normal;
    font-weight: calc(#{$font-weight} + 100);
    line-height: 27px;
    letter-spacing: 0;
}

@mixin h2-regular-20 {
    font-family: Segoe UI, sans-serif;

    font-size: 20px;
    font-style: normal;
    font-weight: calc(#{$font-weight} + 100);
    line-height: 27px;
    letter-spacing: 0;
}

@mixin h3-semibold-16 {
    font-family: Segoe UI, sans-serif;

    font-size: 16px;
    font-style: normal;
    font-weight: calc(#{$font-weight} + 100);
    line-height: 21px;
    letter-spacing: 0;
}

@mixin body-semibold-18 {
    font-family: Segoe UI, sans-serif;

    font-size: 18px;
    font-style: normal;
    font-weight: calc(#{$font-weight} + 100);
    line-height: 21px;
    letter-spacing: 0;
}

@mixin body-regular-16 {
    font-family: Segoe UI, sans-serif;

    font-size: 16px;
    font-style: normal;
    font-weight: calc(#{$font-weight} - 100);
    line-height: 21px;
    letter-spacing: 0;
}

@mixin body-semibold-14 {
    font-family: Segoe UI, sans-serif;

    font-size: 14px;
    font-style: normal;
    font-weight: calc(#{$font-weight} + 100);
    line-height: 19px;
    letter-spacing: 0;
}

@mixin body-regular-14 {
    font-family: Segoe UI, sans-serif;

    font-size: 14px;
    font-style: normal;
    font-weight: calc(#{$font-weight} - 100);
    line-height: 19px;
    letter-spacing: 0;
}

@mixin body-semibold-12 {
    font-family: Segoe UI, sans-serif;

    font-size: 12px;
    font-style: normal;
    font-weight: calc(#{$font-weight} + 100);
    line-height: 19px;
    letter-spacing: 0;
}

@mixin body-regular-12 {
    font-family: Segoe UI, sans-serif;

    font-size: 12px;
    font-style: normal;
    font-weight: calc(#{$font-weight} - 100);
    line-height: 16px;
    letter-spacing: 0;
}

@mixin body-semibold-16 {
    font-family: Segoe UI, sans-serif;

    font-size: 16px;
    font-style: normal;
    font-weight: calc(#{$font-weight} + 100);
    line-height: 21px;
    letter-spacing: 0;
}

@mixin button-semibold-14 {
    font-family: Segoe UI, sans-serif;

    font-size: 14px;
    font-style: normal;
    font-weight: calc(#{$font-weight} + 100);
    line-height: 19px;
    letter-spacing: 0;
}

@mixin body-light-italic-16 {
    font-family: Segoe UI, sans-serif;

    font-size: 16px;
    font-style: italic;
    font-weight: calc(#{$font-weight} - 200);
    line-height: 21px;
    letter-spacing: 0;
}

@mixin muted {
    color: var(--text-color-muted);
}
