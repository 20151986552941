.links-th-menu {
    top: 12px;

    .mat-menu-content {
        padding: 8px;
    }

    button {
        background-color: inherit;
        border-radius: 4px;
        color: #000;
        font-size: 14px;
        height: 32px;
        line-height: 20px;
        padding: 0 8px;

        &:active,
        &:hover {
            background-color: var(--main-color-transparent);
        }

        mat-icon {
            aspect-ratio: 1;
            margin-right: 6px;
            width: 14px;
        }
    }
}
