form.idable {
    --error-color: #c10000;
    &.hide-errors {
        --error-color: var(--line-color);
    }

    display: grid;
    gap: 0 47px;
    grid-template-columns: 400px;
    text-align: left;

    fieldset {
        border: none;
        display: flex;
        flex-flow: column nowrap;
        position: relative;
        margin: 0;
        padding: 0 0 24px;

        label {
            color: #515151;
            font-size: 14px;
            margin-bottom: 4px;
        }

        mat-icon {
            color: #d7d7d7;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                color: var(--main-color) !important;
            }

            &.in-error {
                left: 0px;
                right: 0px;
                height: 14px;
                width: 14px;
                margin-right: 5px;
            }
        }

        input {
            border: 1px solid var(--line-color);
            box-sizing: border-box;
            border-radius: 5px;
            color: #000;
            font-size: 16px;
            height: 47px;
            letter-spacing: 0em;
            padding: 0 12px;
            text-overflow: ellipsis;

            &:hover {
                border-color: var(--secondary-color);
            }

            &[readonly][disabled] {
                background-color: transparent;
                border: none;
                padding: 0;
            }

            &:invalid {
                border-color: var(--error-color);

                & + span {
                    display: block;
                }
            }
        }

        /* Change the white to any color */
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        span {
            bottom: 5px;
            position: absolute;
            display: none;
            color: var(--error-color);
            font-size: 14px;
            max-width: 100%;
            line-height: 18px;
            padding: 0 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 600;

            &.with-icon {
                padding-left: 17px;
            }
        }
    }
}
