// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@use 'theme.scss' as *;

/* You can add global styles to this file, and also import other style files */
@use 'styles/components/buttons' as *;
@use 'styles/components/form' as *;
@use 'styles/components/select' as *;
@use 'styles/components/slide-toggle' as *;
@use 'styles/components/expansion-panel' as *;
@use 'styles/drag-drop' as *;
@use 'styles/fonts/segoe-ui' as *;
@use 'styles/mixins/font';
@use 'styles/mixins/markdown.scss' as *;
@use 'styles/shared/main.scss' as *;

@include mat.core();

html,
body {
    height: 100%;
}

body {
    --ON: initial;
    --OFF: ;
    margin: 0;
}

body.grabbingCursor * {
    cursor: grabbing !important;
}

hr {
    width: 100%;
    margin-top: 23px;
    border: 0.6px solid #c4c4c491;
}

.success-notification {
    background: #6db23d;
    color: white;
}

.success-notification button {
    background-color: #6db23d;
    color: white;
    border: none;
}

.error-notification {
    background: var(--error);
    color: white;
}

.error-notification button {
    background-color: var(--error);
    color: white !important;
    border: none;
}

.warning-notification {
    background: var(--warning-1);
    color: white;
}

.warning-notification button {
    background-color: var(--warning-1);
    color: white !important;
    border: none;
}

.info-notification {
    background: #1497e0;
    color: white;
}

.info-notification button {
    background-color: #1497e0;
    color: white !important;
    border: none;
}

.bg-gradient-1 {
    background: var(--gradient);
}

.loader-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-transparent {
    background: transparent;
}

.header-border-bottom-line {
    border-bottom: 2px solid var(--line-color) !important;
}

.mat-menu-content {
    background-color: #ffffff;
}

.sencond-btn-menu {
    min-height: 0px !important;
    background-color: #ffffff;
    margin-top: 4px;

    .mat-menu-content {
        padding-top: 4px;
        padding-bottom: 4px;

        .mat-menu-item .mat-icon {
            margin-right: 5px;
            vertical-align: middle;
        }
    }
}

.archived-menu.mat-menu-panel {
    min-height: 0 !important;
    overflow: hidden;
}

.disabled-settings-menu {
    .mat-menu-content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border-radius: 5px;
    }
}

.hidden-settings-menu {
    .mat-menu-content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border-radius: 5px;
    }
}

.delete-node-menu {
    .mat-menu-content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border-radius: 5px;
    }

    &.mat-menu-panel {
        min-height: unset !important;
    }
}

* {
    // Colors:
    --main-color: #2ca1df;
    --main-color-transparent: #2ca0df41;
    --secondary-color: #0a68a6;
    --dark-blue: #054b79;
    --gradient: linear-gradient(
        180deg,
        var(--secondary-color) 0%,
        var(--main-color) 100%
    );
    --background-color-1: #ffffff;
    --background-color-2: #f5f5f5;
    --text-color-1: #000000;
    --text-color-2: #8a8a8a;
    --text-color-3: #4e4e4e;
    --line-blue-color: #0a68a6;
    --text-color-muted: #707070;
    --line-color: #d7d7d7;
    --divider-color: #e0e0e0;
    --remove: #c10000;
    --remove-transparent: #d3535333;
    --autocad: #bf2223;
    --background-color-transparent: rgba(208, 234, 239, 0.4);
    --background-table-header: #eeeeee;
    --main-color-transparent-darker: #0a68a633;
    --hover: #ecf7f9;
    --error: #c10000;
    --warning-1: #f59403;
    --warning-2: #c10000;
    --error-transparent: #f2e9e9;
    --warning-transparent: #f5f0e9;
    --button-menu-hover: #e6e6e6;
    --grey-alternative-color: #626262;

    @include font.body;
}

.ace-editor * {
    font-family: unset;
    font-weight: unset;
    font-size: unset;
}

h1 {
    @include font.h1;
}

h2 {
    @include font.h2;
}

h3 {
    @include font.h3;
}

.h1-semibold-20 {
    @include font.h1-semibold-20;
}

.h2-regular-20 {
    @include font.h2-regular-20;
}

.h3-semibold-16 {
    @include font.h3-semibold-16;
}

.body-semibold-18 {
    @include font.body-semibold-18;
}

.body-regular-16 {
    @include font.body-regular-16;
}

.body-semibold-14 {
    @include font.body-semibold-14;
}

.body-regular-14 {
    @include font.body-regular-14;
}

.body-semibold-16 {
    @include font.body-semibold-16;
}

.button-semibold-14 {
    @include font.button-semibold-14;
}

.body-light-italic-16 {
    @include font.body-light-italic-16;
}

.muted {
    @include font.muted;
}

.ace_autocomplete {
    .ace_line {
        span:first-child {
            padding-left: 15px !important;
        }

        .ace_iconable {
            &:before {
                content: '';
                width: 12px;
                height: 100%;
                left: 0;
                top: 0;
                bottom: 0;
                background-size: 12px 12px;
                background-position: center;
                background-repeat: no-repeat;
                display: inline-block;
                position: absolute;
                font-size: 12px;
            }
        }

        .element:before {
            background-image: url('assets/icons/elements.svg');
        }

        .link:before {
            background-image: url('assets/icons/link.svg');
        }

        .function:before {
            background-image: url('assets/icons/function.svg');
        }

        .tree:before {
            background-image: url('assets/icons/tree.svg');
        }

        .list:before {
            background-image: url('assets/icons/list.svg');
        }
    }
}

ngx-skeleton-loader {
    span {
        margin: 0 !important;
    }
}

.shadow-top-appScrollShadows,
.shadow-bottom-appScrollShadows {
    position: absolute;
    left: 0;
    right: 0;
    height: 10px;
    transition: opacity 0.3s ease;
    z-index: 1000;
    opacity: 0;
}

.shadow-top-appScrollShadows {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.shadow-bottom-appScrollShadows {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.reference-graphic {
    mat-icon {
        position: absolute;
        right: 0;
        bottom: 8px;
        background-color: #eaeaea;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        padding-left: 5px;
        padding-right: 1px;
        margin-right: -1px !important;
        border: 1px solid #eaeaea;
        transition: 0.3s;
    }
}

.hovering {
    .reference-graphic {
        mat-icon {
            border-right: 1px solid var(--main-color);
        }
    }
}


