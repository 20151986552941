@import '../mixins/font.scss';

markdown {
    @mixin h {
        padding-left: 11px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    @mixin h-font {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 30px;
    }

    h1 {
        border-left: 7px solid var(--line-blue-color);
        font-size: 36px;
        line-height: 45px;
        margin-top: 70px;
        margin-bottom: 50px;
        margin-left: -20px;
        @include h;
    }

    h2 {
        @include h;
        @include h-font;
        line-height: 45px;
        margin-top: 40px;
        margin-bottom: 30px;
        margin-left: -20px;
        border-left: 7px solid var(--line-color);
    }

    h3 {
        @include h;
        @include h-font;
        font-size: 24px;
        border-bottom: 2px solid var(--line-color);
    }

    h4 {
        @include h-font;
        font-size: 20px;
    }

    h5 {
        @include h-font;
        font-size: 18px;
    }

    p {
        font-size: 18px;
        line-height: 26px;
    }

    img {
        max-width: 100%;
        box-shadow: 0px 4px 10px #888888;
        border-radius: 5px;
    }

    table {
        border-collapse: collapse;
        thead {
            tr {
                th {
                    background-color: var(--background-table-header);
                    text-align: left;
                    padding-top: 19px;
                    padding-bottom: 17px;
                    padding-left: 10px;
                    padding-right: 14px;
                    font-weight: 600;
                }
            }
        }
        tbody {
            tr {
                td {
                    border-bottom: 1px solid var(--line-color);
                    padding: 10px;
                }
            }
        }
    }

    blockquote {
        background-color: #eeeeee;
        border-left: 3px solid var(--line-blue-color);
        padding: 8px 60px 13px 34px;
    }

    code {
        font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
        color: var(--text-color-1);
        background-color: #e3e3e3;
        padding: 2px 5px;
        margin-top: 3px;
        margin-bottom: 3px;
    }

    a {
        color: var(--secondary-color);
        text-decoration: none;
        font-weight: 600;

        &:hover {
            text-decoration: underline;
        }
    }

    strong {
        font-weight: bold;
        * {
            font-weight: bold;
        }
    }

    :not(pre) > code[class*='language-'],
    pre[class*='language-'] {
        background: #ffffff;
    }

    code[class*='language-'],
    pre[class*='language-'] {
        color: var(--text-color-1);
        text-shadow: 0 1px rgb(0 0 0 / 30%);
        font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
        font-size: 1em;
        text-align: left;
        white-space: pre;
        word-spacing: normal;
        word-break: normal;
        word-wrap: normal;
        line-height: 1.5;
        tab-size: 4;
        -webkit-hyphens: none;
        hyphens: none;
    }
}
