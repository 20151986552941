.mat-checked {
    .mat-slide-toggle-bar {
        background-color: var(--main-color-transparent);
    }

    .mat-slide-toggle-thumb {
        background-color: var(--main-color);
    }
}
.mat-slide-toggle-bar {
    background-color: var(--line-color);
}
.mat-slide-toggle-thumb {
    background-color: var(--background-color-1);
}

.mat-slide-toggle-content {
    font-family: Segoe UI, 'Helvetica Neue', sans-serif;
    font-weight: 600;
}
